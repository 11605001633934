.hero {
  text-align: left;

  padding-top: 88px;
  padding-bottom: 120px;
}

.inner-hero {
  display: flex;
  justify-content: space-between;
}

.hero-left {
  padding-top: 40px;
  /* padding-right: 48px;
  min-width: 448px;
  max-width: 512px; */
  z-index: 1;
  position: relative;
  flex-basis: 50%;
  flex-grow: 0;
}
.hero-media {
  position: relative;
  z-index: 0;
  flex-basis: 50%;
  flex-grow: 1;
}

.header-illustration {
  display: block;
}
.header-illustration-image {
  -webkit-animation: fadeIn 3s both cubic-bezier(0.3, 0, 0.2, 1);
  animation: fadeIn 3s both cubic-bezier(0.3, 0, 0.2, 1);
  will-change: transform;
  opacity: 1;
  transition: all 0.4s ease-out;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
}

@media (min-width: 641px) {
  .header-illustration-image {
    display: block;
    position: absolute;
    top: -168px;
    left: -715px;
    width: 1440px;
    height: 324px;
  }
}

.hero-media-illustration {
  position: absolute;
  top: -10%;
  left: -15px;
}

.hero-media-illustration-image {
  -webkit-animation: fadeInLeftIllustration 1s 0.2s both
    cubic-bezier(0.3, 0, 0.2, 1);
  animation: fadeInLeftIllustration 1s 0.2s both cubic-bezier(0.3, 0, 0.2, 1);
  will-change: transform;
  display: block;
  opacity: 1;
}
@keyframes fadeInLeftIllustration {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.95) translate3d(40px, 10px, 0);
    transform: scale(0.95) translate3d(40px, 10px, 0);
  }
}
.hero-media-container {
  position: relative;
}
.hero-media-image {
  -webkit-animation: fadeInLeftMedia 1s 0.2s both cubic-bezier(0.3, 0, 0.2, 1);
  animation: fadeInLeftMedia 1s 0.2s both cubic-bezier(0.3, 0, 0.2, 1);
  will-change: transform;
  margin: 0 auto;

  border-radius: 4px;
  -webkit-transform: perspective(1000px) rotateY(16deg) rotateX(2deg)
    rotateZ(-7deg) scaleY(0.95) translatex(2%);
  transform: perspective(1000px) rotateY(16deg) rotateX(2deg) rotateZ(-7deg)
    scaleY(0.95) translatex(2%);
  box-shadow: 48px 16px 48px rgba(0, 0, 0, 0.2);
}
@keyframes fadeInLeftMedia {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.8) translate3d(40px, 10px, 0);
    transform: scale(0.8) translate3d(40px, 10px, 0);
  }
  30% {
    opacity: 1;
  }
}

.hero-tag {
  font-size: 2rem;
  margin-bottom: 1rem;
}
.hero-name {
  color: #5dbcf0;
  font-size: 3rem;
  letter-spacing: 2px;
  margin-bottom: 8px;
}
.hero-dash {
  width: 8rem;
  height: 7px;
  border-radius: 8rem;
  background: #f0a03c;
  display: block;
  margin-left: 1rem;
}
.hero-para {
  color: #8595ae;
  font-size: 20px;
  line-height: 30px;
  margin-top: 1rem;
  margin-right: 5rem;
}
.hero-link-helper {
  display: block;
  font-size: small;
  color: #cccccc;
  margin-bottom: 8px;
}
.hero-links {
  margin-top: 1rem;
  display: flex;
}

.button {
  display: inline-flex;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  letter-spacing: 0px;
  font-weight: 600;
  line-height: 16px;
  margin-right: 2rem;
  margin-bottom: 1rem;
  text-decoration: none !important;
  text-transform: uppercase;
  background-color: #e9edf3;
  color: #535fd7 !important;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  min-width: 170px;
  justify-content: center;
  padding: 16px 0;
  box-shadow: 48px 16px 48px rgba(0, 0, 0, 0.2);

  text-align: center;
  white-space: nowrap;
}
.button-primary {
  color: #fff !important;
  background-color: #535fd7;
}
.hero-cta {
  margin-top: 2rem;
  display: flex;
}

/* .site-header {
  height: 70px;

  position: relative;
} */
.resume-preview-right-icon{
  margin-left: 2rem;
}