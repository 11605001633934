/* .portfolio-title {
  font-size: 3rem;
  color: #e9edf3;
  letter-spacing: 1px;
} */
.portfolio-para {
    margin-right: 0 !important;
}
.desc-project {
    flex-basis: 55%;
    height: 230px;
    margin: auto 2rem;
    flex-grow: 0;
}
.fileup_home_img {
    border-radius: 4px;
    box-shadow: 48px 16px 48px rgba(0, 0, 0, 0.2);
}

/* .project-title {
  font-size: xx-large;
  color: #e9edf3;
} */
.icons-style {
    width: 50%;
    margin: 3px 9px;
}
.tech-icosns-holder {
    display: flex;
    flex-direction: column;
    background-color: aliceblue;
    box-shadow: 48px 16px 48px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    width: 10%;
    text-align: center;
    justify-content: space-around;
}
/* .project-link-helper {
  font-size: small;
  color: #cccccc;
  margin: 8px;
} */

@media (max-width: 1024px) {
    .tech-icosns-holder {
        display: none;
    }
}
