/* .cta::before {
  content: "";
  position: absolute;
  bottom: -32px;
  left: calc(50% - 720px);
  height: 263px;
  width: 1440px;
  background-image: url("../images/light/cta-illustration-light.svg");
} */
/* .cta {
  position: relative;
} */
.cta-cta {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}
.section {
  height: 25rem;
}
/* @media (max-width: 600px) {
  .cta::before {
    display: none;
  }
} */
